import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"
import StaticBG from "../assets/images/pages/students-hero.jpg"

export default function Students({ data }) {
  return (
    <Layout>

<Seo
        title="Students"
        description="Information for Students"
      />

<div id="students" className="brochure">
		
        <section
          id="brochure-hero" 
          style={{ backgroundImage: "url(" + StaticBG + ")" }}
        >
					<div id="brochure-hero-overlay">
						<div className="intro">
							<h2>Students</h2>
							<div className="tagline">What are the Awards? A platform of expression for creative teens making original work!</div>
						</div>
					</div>
				</section>
				<section id="brochure-features">
					<div id="students-how" className="feature-thumb">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3>How to Apply</h3>
							<p>Create your account, read the guidelines, and upload your work.</p>
							<Link to="/awards/how-to-enter/" className="button">Get Started</Link>
						</div>
					</div>
					<div id="students-why" className="feature-cover">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3><span>Why You Should Enter</span></h3>
							<p>Share your voice and vision, and your work may be selected for exhibition, publication, scholarships, and more.</p>
							<Link to="/awards/recognition/" className="button">Learn More</Link>
						</div>
					</div>
					<div className="feature-rule"><hr /></div>
					<div id="students-deadline" className="feature-thumb-alt">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3>Find Your Deadline</h3>
							<p>Submission deadlines vary by region. Find your regional program.</p>
							<Link to="/regions" className="button">Find Deadline</Link>
						</div>
					</div>
					<div id="students-work" className="feature-cover-alt" >
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3><span>View Award-Winning Work</span></h3>
							<p>Take a look at what the other teens around the country are creating by visiting our gallery.</p>
							<Link to="/gallery" className="button">View the Work</Link>
						</div>
					</div>
					<div className="feature-rule"><hr /></div>
				</section>
        <section id="brochure-faq">
          <div className="faq-widget">
            <h3>FAQ</h3>
            <ul>
            {data.studentFaqs.nodes.map((faq, index) => (
                      <li key={index}>
                        <Link to={faq.uri}>
                        <strong>{faq.title}</strong>
                          <span>{parse(faq.excerpt)}</span>
                        </Link>
                      </li>
                      ))}
            </ul>
            <Link to="/frequently-asked-questions/" className="button">
              View More
            </Link>
          </div>
        </section>
			</div>

    </Layout>
  )
}

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
  query {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
    studentFaqs: allWpFaq(filter: {faqTags: {nodes: {elemMatch: {slug: {eq: "students"}}}}}) {
    nodes {
      id
      title
      content
      excerpt
      uri
    }
  }

  }
`